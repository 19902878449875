<template>
    <v-container class="fill-height">
        <v-row>
            <v-col class="text-center">
                Loading...
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'HoplomachusView',
  components: {
  },
  data: () => ({
  }),
  created() {
    this.$store.hoplomachus.dispatch('hoplomachus/updateState');
  }
}
</script>