import ApiService from "../../../service/ApiService";

export default {
    namespaced: true,
    state: {
        current: null,
        killer: null,
        killerBox: null,
        girl: null,
        girlBox: null,
        location: null,
        locationBox: null,
        loaded: false,
        mode: null,
        mode_string: null
    },
    getters: {
    },
    mutations: {
        setState(state, newStates) {
            state.current = newStates.current
            state.mode = newStates.mode
            state.mode_string = newStates.mode_string
            state.killer = newStates.killer?.name
            state.killerBox = newStates.killerBox?.name
            state.location = newStates.location?.name
            state.locationBox = newStates.locationBox?.name
            state.girl = newStates.girl?.name
            state.girlBox = newStates.girlBox?.name
            state.loaded = true
        },
        setMode(state, newMode) {
            state.mode = newMode.mode
            state.mode_string = newMode.mode_string
        }
    },
    actions: {
        updateState(context) {
            ApiService.getGameState('finalgirl').then((data) => {
                context.commit('finalgirl/setState', data.data, { root: true })
            })
        }
    },
    modules: {
    }
}
