<template>
  <v-container class="fill-height">
    <v-row>
      <v-col class="text-center">
        please wait...
        <pre>{{test}}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiService from '../../service/ApiService';

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    test: {}
  }),

  created() {
    ApiService.checkLogin().then((data) => {
      if (data.data.success) {
        this.$store.dispatch('updateState')
      }
      this.$router.push({ name: 'home'})
    })
  }
}
</script>
