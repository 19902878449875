<template>
    <v-container class="fill-height">
        <v-row>
            <v-col class="text-center" v-if="!$store.state.finalgirl.loaded">
                Loading...
            </v-col>
            <v-col v-else-if="$store.state.finalgirl.current != null">
                <h1>Final Girl</h1>
                <v-list lines="two">
                    <v-list-item
                        :title="`Location: ${$store.state.finalgirl.location}`"
                        :subtitle="`from ${$store.state.finalgirl.locationBox}`"
                    />
                    <v-list-item
                        :title="`Killer: ${$store.state.finalgirl.killer}`"
                        :subtitle="`from ${$store.state.finalgirl.killerBox}`"
                    />
                    <v-list-item
                        :title="`Girl: ${$store.state.finalgirl.girl}`"
                        :subtitle="`from ${$store.state.finalgirl.girlBox}`"
                    />
                </v-list>
                <v-btn color="red" @click="gameLost">lost</v-btn> <v-btn color="green" @click="gameWon">won</v-btn>
            </v-col>
            <v-col v-else-if="editboxes">
                <v-btn @click="finishOwned">back</v-btn>
                <v-row>
                    <v-col class="box" :class="{ owned: box.owned }" v-for="box in boxes" v-bind:key="box.id" cols="2" @click="toggleOwned(box.id)">
                        <h2>{{ box.name }}</h2>
                        <img :src="box.img" :alt="box.name" class="boximg">
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else>
                <h1>Final Girl</h1>
                <v-btn @click="newGame" :loading="newgame_loading">new game</v-btn> <v-btn @click="toggleMode" :loading="mode_loading">Mode: {{$store.state.finalgirl.mode_string}}</v-btn> <v-btn @click="setOwned" :loading="setOwned_loading">set owned</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ApiService from '../../../service/ApiService';

export default {
  name: 'FinalGirlView',
  components: {
  },
  data: () => ({
    newgame_loading: false,
    mode_loading: false,
    setOwned_loading: false,
    editboxes: false,
    boxes: [],
    test: null,
  }),
  created() {
    this.$store.dispatch('finalgirl/updateState');
  },
  methods: {
    setOwned() {
        this.setOwned_loading = true;
        ApiService.callGameFunction('finalgirl','getboxes').then((data) => {
            this.boxes = data.data;
            this.editboxes = true;
            this.setOwned_loading = false;
        });
    },
    finishOwned() {
        this.editboxes = false;
    },
    newGame() {
        if (!this.newgame_loading) {
            this.newgame_loading = true;
            ApiService.callGameFunction('finalgirl','startnewgame').then((data) => {
                this.test = data.data;
                this.$store.dispatch('finalgirl/updateState').then(() => {
                    this.newgame_loading = false;
                })
            })
        }
    },
    gameLost() {
        ApiService.callGameFunction('finalgirl','gamelost').then(() => {
            this.$store.dispatch('finalgirl/updateState').then(() => {
                this.newgame_loading = false;
            })
        })
    },
    gameWon() {
        ApiService.callGameFunction('finalgirl','gamewon').then(() => {
            this.$store.dispatch('finalgirl/updateState').then(() => {
                this.newgame_loading = false;
            })
        })
    },
    toggleOwned(id) {
        ApiService.callGameFunction('finalgirl','toggleowned', {'id': id}).then((data) => {
            this.boxes = data.data;
        })
    },
    toggleMode() {
        if (!this.mode_loading) {
            this.mode_loading = true;
            ApiService.callGameFunction('finalgirl','togglemode').then((data) => {
                this.$store.commit('finalgirl/setMode', data.data, { root: true })
                this.mode_loading = false;
            })
        }
    }
  }
}
</script>
<style>
.boximg {
    max-width: 180px;
}

.box {
    filter: saturate(0);
    cursor: pointer;

    &.owned {
        filter: saturate(1);
    }
}

</style>