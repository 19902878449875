import { createStore } from 'vuex'
import ApiService from "../../service/ApiService";
import hoplomachus from './modules/hoplomachus';
import finalgirl from './modules/finalgirl';

export default createStore({
  modules: {
    hoplomachus,
    finalgirl
  },
  state: {
    loggedin: false,
    loaded: false,
    nonce: null,
  },
  getters: {
  },
  mutations: {
    setState(state, newStates) {
      state.loggedin = newStates.loggedin
      state.nonce = newStates.nonce
      state.loaded = true
    }
  },
  actions: {
    updateState(context) {
      ApiService.getStatus().then((data) => {
        context.commit('setState', data.data)
      })
    }
  }
})
