<template>
  <v-container class="fill-height">
    <v-row>
      <v-col>
        <h1>Welcome</h1>
        <p>Choose your game</p>
        <div>
          <div class="game" v-for="game in games" v-bind:key="game.name">
            <router-link :to="{ name: game.route }"><img :src="`/img/games/${game.image}`"></router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  data: () => ({
    games: [
      {
        name: 'Final Girl',
        image: 'final_girl.png',
        route: 'final_girl',
      },
      {
        name: 'Hoplomachus Victorum',
        image: 'hoplomachus_victorum.png',
        route: 'hoplomachus',
      },
    ]
  }),
  components: {
  }
}
</script>

<style scoped lang="scss">
.game {
  display: inline-block;

  img {
    max-width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 40px;
  }
}
</style>