import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from '../views/AuthView.vue'
import FinalGirlView from '../views/game/FinalGirlView.vue'
import HoplomachusVictorumView from '../views/game/HoplomachusVictorumView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: {
      public: true,
      x: 'y'
    }
  },
  {
    path: '/game/final_girl',
    name: 'final_girl',
    component: FinalGirlView
  },
  {
    path: '/game/hoplomachus',
    name: 'hoplomachus',
    component: HoplomachusVictorumView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
