import axios from 'axios';

const apiClient = axios.create({
    baseURL: '/api/',
    withCredentials: false, // This is the default
    timeout: 2000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export default {
    getStatus() {
        return apiClient.get('status');
    },
    checkLogin() {
        return apiClient.get('login');
    },
    getGameState(game) {
        return apiClient.get(`${game}/gamestate`);
    },
    callGameFunction(game, func, args = {}) {
        console.log(args);
        return apiClient.post(`${game}/func/${func}`, args)
    }
};
