<template>
  <v-app dark>
    <v-main v-if="$store.state.loaded">
      <LoginComponent v-if="!$store.state.loggedin && !$route.meta.public" />
      <router-view v-else/>
    </v-main>
  </v-app>
</template>

<script>
import LoginComponent from "@/components/LoginComponent.vue";

export default {
  name: 'App',

  components: {
    LoginComponent,
  },

  data: () => ({
  }),

  created() {
    this.$store.dispatch('updateState')
  }
}
</script>
