import ApiService from "../../../service/ApiService";

export default {
  namespaced: true,
  state: {
    test: 'x'
  },
  getters: {
  },
  mutations: {
    setState(state, newStates) {
      console.log(newStates)
//        state.loggedin = newStates.loggedin
//      state.nonce = newStates.nonce
//      state.loaded = true
    }
  },
  actions: {
    updateState(context) {
        ApiService.getGameState('hoplomachus').then((data) => {
            context.commit('setState', data.data)

        })
    }
  },
  modules: {
  }
}
